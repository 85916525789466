<template>
  <div class="register-container">
    <div class="register-form-wrapper">
      <p class="fontsize_24 color_222 fontweight_600 margin_bot16">{{currentData.title}}</p>
      <p class="fontsize_16 margin_bot24"><span class="color_222">{{currentData.tips1}}</span><span class="color_1373E6 pointer hoverColor_126AD4" @click="tapLogin">{{currentData.tips2}}</span></p>
      <el-form @submit.native.prevent :model="ruleForm" status-icon :rules="rules" :show-message="false" ref="ruleForm" label-width="120px" label-position="left" class="register-form">
          <el-form-item :prop="Array.isArray(item) ? '' :item.props" label-width="0" v-for="(item,index) in currentData[mode]" :key="item.name" class="margin_bot24">
            <div v-if="Array.isArray(item)" class="flex flex_jcbetween">
              <el-form-item :prop="sitem.props" v-for="(sitem,sind) in item" :key="sitem.props" :class="{'margin_left16':sind===1}">
                <el-input  
                  class="login-input-wrapper"
                  :type="getInputType(sitem.inputType, sitem.props)" 
                  v-model="ruleForm[sitem.props]" 
                  autocomplete="new-password"  :placeholder="sitem.placeholder" 
                  >
                </el-input>
                <div class="input-error-msg" v-if="errMsgSet[sitem.props]">
                  <i class="icon-warning fontsize_12"></i><span class="padding_left8 color_E74362 fontsize_12">{{errMsgSet[sitem.props]}}</span>
                </div>
              </el-form-item>
            </div>
            <el-input v-else :type="getInputType(item.inputType,item.props)" v-model="ruleForm[item.props]" autocomplete="new-text" auto-complete="new-text" :placeholder="item.placeholder" class="login-input-wrapper">
              <span  v-if="item.propName === 'mobileCode' && !mobileCodeData.hasCode" slot="suffix" class="fontsize_12 color_E74362 pointer valid-code-btn hoverColor_CC3350" :class="{'is-disabled':!ruleForm.mobile}"  @click="getMobileCode">获取验证码</span>
              <span  v-else-if="item.propName === 'mobileCode' && mobileCodeData.hasCode" slot="suffix" class="fontsize_12 color_A1A1A1 pointer valid-code-btn">重新获取(<i style="font-style: normal;">{{mobileCodeData.countdownTime}}</i>)</span>
            </el-input>
            <div class="input-error-msg" v-if="!Array.isArray(item) && errMsgSet[item.props]">
              <i class="icon-warning fontsize_12"></i><span class="padding_left8 color_E74362 fontsize_12">{{errMsgSet[item.props]}}</span>
            </div>
            <div v-if="index === 0 && otherModes.length > 1" style="line-height:32px;">
              <p class="fontsize_14 color_A1A1A1">改用<span class="padding_0_4 color_E74362 pointer hoverColor_D0435E" @click="changeMode(otherModes[0].type)">{{otherModes[0].title}}</span>或
              <span class="padding_0_4 color_08DD6D pointer" @click="changeMode(otherModes[1].type)"><i class="icon-wechat icon_color_0AC061 padding_right4 fontsize_12"></i>{{otherModes[1].title}}</span>注册</p>
            </div>
          </el-form-item>
          <div v-if="mode !== 'wechat'" class="flex flex_acenter flex_jcbetween margin_bot24">
            <checkBox key="showpass" 
              :value="isShowPass" :label="'显示密码'" :labelFontSize="12" :scale="0.6" :labelPaddingLeft="5"
              @change="changeShowPass" class="show-pwd-checkbox">
            </checkBox>
          </div>
          <div v-if="mode === 'wechat'" class="wechat-wrapper">
            <div class="wechat-code-wrapper">
              <img @click="tapWechatCode" :src="wechatData.img" alt="" class="wechat-code-img">
            </div>
            <p class="textcenter flex flex_acenter">
              <i class="icon-wechat fontsize_16 icon_color_0AC061"></i>
              <span class="fontsize_14 color_222 padding_left4">扫码关注公众号完成注册</span>
            </p>
          </div>
          <div v-if="mode === 'wechat'" class="textcenter margin_top24">
              <p class="fontsize_14 color_A1A1A1">改用<span class="padding_0_4 pointer color_E74362 hoverColor_D0435E" @click="changeMode('phone')">手机号</span>或
              <span class="padding_0_4 pointer color_E74362 hoverColor_D0435E" @click="changeMode('email')">邮箱</span>注册</p>
            </div>
          <customButton  v-if="mode !== 'wechat'" @click="submitForm('ruleForm')" color="#E74362" hoverBgColor="#CC3350" width="100%" :roundPX="4" :height="48"><span class="fontsize_14">{{currentData.submitBtn.name}}</span></customButton>
      </el-form>
    </div>
  </div>
</template>

<script>
// import { loginApi } from '@/libs/request/api.js';
import { getWechatQrCode, checkSceneStr, sendMobileCodeOfRegister, sendEmailCodeOfRegister, registerWithMobile, registerWithEmail } from '@/libs/api/login'
import { mapActions } from 'vuex'
import customButton from "@/renderer/components/common/button.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  components: {
    customButton,
    checkBox
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      let errorMsgObj = {
        "phone":"手机号格式不正确",
        "email":"邮箱格式不正确",
      };
      let reg = this.mode==='phone' ? /^1\d{10}$/ : /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '' || !reg.test(value)) {
        callback(new Error(errorMsgObj[this.mode]));
        // this.errMsgSet[rule.field] = errorMsgObj[this.mode];
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validatePass = (rule, value, callback) => {
      let reg = /^[A-Za-z0-9]+$/;
      if (value === '') {
        callback(new Error('密码不正确'));
        // this.errMsgSet[rule.field] = "密码不正确";
      }else if(value && value.length < 8) {
        callback(new Error('密码至少8位'));
        // this.errMsgSet[rule.field] = "密码至少8位";
      }
      else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
      // else if(value && !reg.test(value)) {
       // callback(new Error('密码 只能由字母和数字组成'));
        // this.errMsgSet[rule.field] = "密码 只能由字母和数字组成";
      //} 
    };
    var validateRePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不正确'));
        // this.errMsgSet[rule.field] = "确认密码不正确";
      }else if(value !== this.ruleForm.password) {
        callback(new Error('与密码不一致'));
        // this.errMsgSet[rule.field] = "与密码不一致";
      }else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('验证码不正确'));
        // this.errMsgSet[rule.field] = "验证码不正确";
      } else {
        callback();
        // this.errMsgSet[rule.field] =  "";
      }
    };
    return {
      type:"register", 
      mode:"phone", // phone、email 、wechat
      isShowPass:false, //是否显示密码
      mobileCodeData: {
        hasCode: false,
        timer:null,
        countdownTime: 30, //倒计时s
        requesting:false, //请求中
      },
      wechatData: {
        img:"", //二维码
        codeDetail:{},
        timer: null, //定时检测是否扫码,
        duration:2000, //ms = 1s，
      },
      contentData: {
        register: {
          title: "注册",
          tips1:"已有账号，",
          tips2:"马上登录",
          // modeTitle:"其他登录方式",
          modeArr:[
            {
              type:"phone",
              title:"手机号",
            },
            {
              type:"email",
              title:"邮箱",
            },
            {
              type:"wechat",
              title:"微信",
              iconClassName:"icon-wechat"
            }
          ],
          phone:[
            {
              props:"mobile", //绑定字段
              inputType:"text",
              propName:"account", //
              name:"手机号",
              placeholder:"手机号",
            },
            {
              props:"verification_code",
              inputType:"text",
              propName:"mobileCode", //
              name: "验证码",
              placeholder:"验证码",
            },
            [
              {
                props:"password",
                inputType:"password",
                propName:"password", //
                name: "密码",
                placeholder:"密码",
                width:"50%"
              },
              {
                props:"password_confirmation",
                inputType:"password",
                propName:"repassword", //
                name: "确认密码",
                placeholder:"再次确认",
                width:"50%"
              },
            ]
          ],
          email: [
            {
              props:"mobile", 
              inputType:"email",
              propName:"email", //
              name: "邮箱",
              placeholder:"邮箱",
            },
            {
              props:"verification_code",
              inputType:"text",
              propName:"mobileCode", //
              name: "验证码",
              placeholder:"验证码",
            },
            [
              {
                props:"password",
                inputType:"password",
                propName:"password", //
                name: "密码",
                placeholder:"密码",
                width:"50%"
              },
              {
                props:"password_confirmation",
                inputType:"password",
                propName:"repassword", //
                name: "确认密码",
                placeholder:"再次确认",
                width:"50%"
              },
            ]
          ],
          wechat: {

          },
          showPassword: {
            name:"显示确认密码",
            form_type:"checkbox"
          },
          submitBtn: {
            name:"注册",
            type:"register"
          }
        },
      },
      ruleForm: {
        mobile: "",
        verification_code: "",
        password: "",
        password_confirmation: "",
      },
      errMsgSet: {
        mobile:"",
        password: "",
        verification_code: "",
        password_confirmation: "",
      },
      rules: {
        mobile: [
          { validator: validateMobile, trigger: 'submit' }
        ],
        password: [
          { validator: validatePass, trigger: 'submit' }
        ],
        verification_code: [
          { validator: validateCode, trigger: 'submit' }
        ],
        password_confirmation: [
          { validator: validateRePass, trigger: 'submit' }
        ],
      },
      propMap: { //校验filed
        mobile: "mobile",
        email:"mobile",
        password: "password",
        password_confirmation:"password_confirmation",
        verification_code: "verification_code",
      },
    };
  },
  computed: {
    currentData() {
      return this.contentData[this.type];
    },
    otherModes() { //其他注册方式
      return this.currentData.modeArr.filter(v => v.type !== this.mode) || [];
    },
  },
  beforeDestroy() {
    this.wechatData.timer && clearInterval(this.wechatData.timer);
    this.mobileCodeData.timer && clearInterval(this.mobileCodeData.timer);
  },
  methods: {
    ...mapActions([
      'Login',
      'GetUserInfo',
      'LoginWithPwd',
      'LoginWithShortMsg',
      'SaveToken'
    ]),
    tapLogin() {
      // 点击登录或者注册
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      this.$router.push({name: "Login"})
    },
    submitForm(formName) {
      // 重置错误信息
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");

      this.$refs[formName].validate((valid,obj) => {
        if (valid) {
          this.putRegister();
        } else {
          console.log('error submit!!');
          Object.keys(obj).forEach(v => {
            this.errMsgSet[v] = obj[v][0].message;
          });
          return false;
        }
      });
    },
    putRegister(args) {
      let registerfrom = this.$route.query && this.$route.query.registerfrom || '';
      let secretstr = this.$route.query && this.$route.query.secretstr || '';
      let { mobile, verification_code, password, password_confirmation } = this.ruleForm;
      if(password.length < 8) {
        // this.$message("密码长度不小于8位");
        this.errMsgSet["password"] = "密码长度不小于8位";
        return;
      }
      if(password !== password_confirmation) {
        // this.$message("2次密码输入不一致");
        this.errMsgSet["password"] = "2次密码输入不一致";
        return;
      }
      switch(this.mode) {
        case "phone":
          registerWithMobile({ mobile, verification_code, password, password_confirmation, registerfrom,secretstr }).then( (res=>{
            if(res && res.errorcode == 0) {
              //获取用户信息
              this.$message.success(res.msg);
              this.$router.push({name:'Login'});
            }else if(res.errorcode == 1 && res.data){
              // 错误提示
              Object.keys(res.data).forEach(v => {
                this.errMsgSet[this.propMap[v]] = res.data[v][0];
              });
            }
          })).catch((err)=>{});
          break;
        case "email":
          registerWithEmail({ email: mobile, verification_code, password, password_confirmation, registerfrom,secretstr }).then( (res=>{
            if(res && res.errorcode == 0) {
              //获取用户信息
              this.$message.success(res.msg);
              this.$router.push({name:'Login'});
            }else if(res.errorcode == 1 && res.data){
              // 错误提示
              Object.keys(res.data).forEach(v => {
                this.errMsgSet[this.propMap[v]] = res.data[v][0];
              });
            }
          })).catch((err)=>{});
          break;
      }
      
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      Object.keys(this.errMsgSet).forEach(v => this.errMsgSet[v] = "");
    },
    changeShowPass(value) {
      // 切换-是否显示密码
      this.isShowPass = value;
    },
    getInputType(type, props) {
      // 输入框类型
      let mode = this.mode;
      // 账号模式-显示密码时，密码框改为文本框
      if((props === "password" || props === "password_confirmation") && this.isShowPass) return "text";
      return type;
    },
    changeMode(mode) {
      // 改变登录/注册方式
      this.mode = mode;
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      // 重置表单
      this.resetForm("ruleForm");

      Object.keys(this.ruleForm).forEach(v => {
        this.ruleForm[v] = "";
      });
      if(this.mode === 'wechat') {
        // 获取微信二维码
        this.tapWechatCode();
      }
    },
    getMobileCode() {
      // 获取验证码
      let mobile = this.ruleForm.mobile;
      // 请求中
      if(this.mobileCodeData.requesting) return;
      // 倒计时中
      if(this.mobileCodeData.hasCode) return;
      if(!mobile) {
        let errMsg = this.mode === "phone" ? "请输入手机号！" : "请输入邮箱";
        this.$message.warning(errMsg);
        return;
      }
      this.mobileCodeData.requesting = true;
      this.mobileCodeData.hasCode = true;
      // 倒计时
      this.mobileCodeData.countdownTime --;
      this.mobileCodeData.timer = setInterval(()=>{
        if(this.mobileCodeData.countdownTime <= 1) {
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.hasCode = false;
          this.mobileCodeData.countdownTime = 30;
        }
        this.mobileCodeData.countdownTime --;
      },1000)
      // 发送验证码
      this.mode === "phone" ? this.sendCodeOfPhone(mobile) : this.sendCodeOfEmail(mobile);
    },
    sendCodeOfEmail(mobile) {
      // 发送注册邮箱 验证码
      sendEmailCodeOfRegister(mobile).then(res => {
        this.mobileCodeData.requesting = false;
        if(res.errorcode == 0) {
          this.$message.success(res.msg);
        }else {
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      }).catch(()=>{
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      });
    },
    sendCodeOfPhone(mobile) {
      // 发送注册邮箱 验证码
      sendMobileCodeOfRegister(mobile).then(res => {
        this.mobileCodeData.requesting = false;
        if(res.errorcode == 0) {
          this.$message.success(res.msg);
        }else {
          this.mobileCodeData.hasCode = false;
          clearInterval(this.mobileCodeData.timer);
          this.mobileCodeData.countdownTime = 30;
        }
      }).catch(()=>{
        this.mobileCodeData.requesting = false;
        this.mobileCodeData.hasCode = false;
        clearInterval(this.mobileCodeData.timer);
        this.mobileCodeData.countdownTime = 30;
        this.$message.warning("验证码发送失败！");
      });
    },
    tapWechatCode() {
      // 获取微信二维码
      this.wechatData.timer && clearInterval(this.wechatData.timer);
      getWechatQrCode().then((res) => {
        if(res.errorcode == 0) {
          this.wechatData.codeDetail = res.data;
          this.wechatData.img = res.data.qrcode_url;
          // 检测扫码状态
          this.checkSceneStatus();
          this.$forceUpdate();
        }
      }).catch();
    },
    checkSceneStatus() {
      // 检测扫码状态
      this.wechatData.timer = setInterval(() => {
        checkSceneStr(this.wechatData.codeDetail.scene_str).then(res => {
          if(res.errorcode == 0) {
            // 扫码成功
            if(res.data.status == 1) {
              this.$message.success(res.msg);
              this.wechatData.timer && clearInterval(this.wechatData.timer);
              this.SaveToken(res.data.token);
              //获取用户信息
              this.GetUserInfo();
              this.$router.push({name:'Home'});
              return;
            }
            // 随机字符串对应的值不存在或已过期
            if(res.data.status == -1) {
              this.wechatData.timer && clearInterval(this.wechatData.timer);
              this.tapWechatCode();
              return;
            }
          }else {
            this.wechatData.timer && clearInterval(this.wechatData.timer);
          }
        }).catch();
      }, this.wechatData.duration);
    },
  }
}
</script>

<style lang="less" scoped>
    .register-container {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .register-form-wrapper {
      box-sizing: border-box;
      // margin: 113px auto 0 20%;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 40px 40px 47px;
      width: 472px;
      box-shadow: 0 2px 21px 0 rgba(0,0,0,0.15);
      background: #FFFFFF;
    }
    .register-form {
    }
    .other-mode-title {
      position: relative;
      padding: 24px 0;
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        display: block;
        content: "";
        width: 138px;
        height: 1px;
        background-color: #D4D4D4;
      }
      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    }
    // 微信登陆
    .wechat-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.15);
      padding: 45px 0;
      width: 100%;
      background: #FFFFFF;
      .wechat-code-wrapper {
        margin-bottom: 30px;
        box-sizing: border-box;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(~@/assets/user/red_frame1@3x.png), url(~@/assets/user/red_frame2@3x.png),
          url(~@/assets/user/red_frame3@3x.png), url(~@/assets/user/red_frame4@3x.png);
        background-repeat: no-repeat;
        background-position: left top, right top, left bottom, right bottom;
        background-size: 25px 25px;
      }
      .wechat-code-img {
        width: 140px;
        height: 140px;
        object-fit: fill;
      }
    }
    .valid-code-btn {
      padding: 4px 15px;
      &.is-disabled {
        color: #A1A1A1;
      }
    }
</style>